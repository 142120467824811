<template>
    <div class="serveSite_page">
        <new-header :config="headerConfig"></new-header>

        <vue-loadmore :on-refresh="onRefresh" :on-loadmore="onLoad" :finished="finished">
            <div class="card_div" v-for="(item, index) in list" :key="index">
                <div class="card">
                    <div class="card_left">
                        <div class="text_1">{{ item.shop_name }}</div>
                        <div class="text_2">门店地址：{{ item.shop_address }}</div>
                        <div class="text_2">营业时间：{{ item.shop_time }}</div>
                    </div>
                    <div class="card_right">
                        <van-icon @click="gotoTencentApp(item)" class="address_icon" :name="addressIcon" />
                        <div class="text_3">距离:{{ item.distance || 0 }}</div>
                    </div>
                </div>
                <van-divider />
            </div>
        </vue-loadmore>
    </div>
</template>
<script>
import addressIcon from '@/assets/personalIcon/addressIcon.png'

import newHeader from "@/components/newHeader";

import { getShopList_api } from "@/api/perdata"
export default {
    components:{
        newHeader
    },
    data() {
        return {
            addressIcon: '',
            headerConfig: {
                show: true,
                title: '服务站点',
            },

            location: {},
            query: { page: 1, limit: 10 },
            pages: 0,
            finished: false,
            list: [],

        }
    },
    created() {
        this.addressIcon = addressIcon

        this.getLocation()
    },
    methods: {
        onRefresh(done) {
            this.list = [];
            this.query.page = 1;
            this.finished = false;
            this.getList();
            done();
        },
        onLoad(done) {
            if (this.query.page >= this.pages) {
                this.finished = true;
            } else {
                this.fetch();
            }
            
            done();
        },
        fetch(){
            this.query.page++;
            this.getList();
        },
        getLocation() {
            //获取当前位置的经纬度
            var data = {
                key: "77OBZ-EAICZ-URGXD-7VPUP-WAVTO-O2F55" //这个key就是你申请的密钥
            };
            var url = "https://apis.map.qq.com/ws/location/v1/ip"; //这个就是地理位置信息的接口
            data.output = "jsonp";
            this.$jsonp(url, data)
                .then(res => {
                    this.location = res.result.location
                    this.getList()
                })
                .catch(error => {
                    this.$toast.err(error)
                    this.getList()
                })
        },
        async getList() {
            let param = JSON.parse(JSON.stringify(this.query))
            param.latitude = this.location.lat
            param.longitude = this.location.lng
            let res = await getShopList_api(param)
            if (res.code == 0) {
                res.data.forEach(item => {
                    item.shop_time = `${item.attend_start}-${item.attend_end}`
                    item.distance = item.distance.substring(0, item.distance.length - 3) + 'km'
                })
                this.list = this.list.concat(res.data)
                this.pages = res.pagination.pages
            }
        },

        // 点击前往腾讯地图app导航
        gotoTencentApp(item) {

            window.location.href = `https://apis.map.qq.com/tools/routeplan/
            sword=我的位置&spointx=${this.location.lat}&spointy=${this.location.lng}
            &eword=${item.shop_address}&epointx=${item.shop_latitude}&epointy=${item.shop_longitude}
            ?referer=myapp&key=77OBZ-EAICZ-URGXD-7VPUP-WAVTO-O2F55`

            // let fromcoord = `${this.location.lat},${this.location.lng}`
            // let tocoord_name = item.shop_name
            // let tocoord = `${item.shop_latitude},${item.shop_longitude}`

            // window.location.href = `https://apis.map.qq.com/uri/v1/routeplan
            // ?type=bus
            // &from=我的位置&fromcoord=${fromcoord}
            // &to=${tocoord_name}&tocoord=${tocoord}
            // &policy=1
            // &referer=OB4BZ-D4W3U-B7VVO-4PJWW-6TKDJ-WPB77`;
        },
    }
}
</script>
<style scoped>
.serveSite_page {
    box-sizing: border-box;
    width: 100%;
    min-height: 100vh;
    padding: 30px 35px;
}

.card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 28px;
}

.text_1 {
    font-size: 28px;
    color: #1A1A1A;
    line-height: 33px;
    margin-bottom: 14px;
    font-weight: bold;
}

.text_2 {
    font-size: 26px;
    color: rgba(26, 26, 26, 0.6);
    line-height: 30px;
    margin-bottom: 6px;
}

.text_1,
.text_2 {
    width: 480px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.text_3 {
    font-size: 24px;
    color: rgba(26, 26, 26, 0.6);
    line-height: 30px;
    margin-bottom: 6px;
    width: 170px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.address_icon {
    font-size: 56px;
    margin-bottom: 10px;
}

.card_right {
    text-align: center;
}
</style>